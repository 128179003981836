<!-- 注销账号 -->
<template>
  <div class="CancellationAccount">
    <van-field type="digit" v-model="phone" clearable center maxlength="11" placeholder="请输入手机号" />
    <van-field type="digit" v-model="code" center maxlength="6" placeholder="请输入验证码" @input="codeInput">
      <template #button>
        <span v-show="second === maxSecond && phone.length === 11" class="active" @click="getCode">
          获取验证码
        </span>
        <span v-show="second === maxSecond && phone.length !== 11">
          获取验证码
        </span>
        <span v-show="second < maxSecond">{{ second }}s后重新获取</span>
      </template>
    </van-field>
    <div class="loginBtn" @click="confirmClick">
      确定
    </div>
  </div>
</template>

<script>
import { getMessageCode } from "@/api/api_personal";
import { cancelUser } from "@/api/api_personal";
import { mapState } from "vuex";

export default {
  name: "Login",
  data () {
    return {
      phone: "",
      code: "",
      second: 60,
      maxSecond: 60,
      timer: null,
      isOk: false
    };
  },
  methods: {
    codeInput (code) {
      if (code.length === 6 && this.phone.length === 11) {
        this.isOk = true;
      } else {
        this.isOk = false;
      }
    },
    // 60s倒计时
    timeDown () {
      this.second--;
      this.timer = setInterval(() => {
        if (this.second === 0) {
          clearInterval(this.timer);
          this.second = this.maxSecond;
        } else {
          this.second--;
        }
      }, 1000);
    },
    // 确认
    confirmClick () {
      if (this.phone == false || this.code == false) {
        this.$toast("请输入手机号或验证码");
        return;
      }
      cancelUser(
        {
          userId: this.loginInfo.userId,
          userPhone: this.phone,
          verifyCode: this.code
        },
        { headers: { token: this.loginInfo.token } }
      ).then(res => {
        if (res.code === 0) {
          this.$store.commit("setLoginInfo", {});
          this.$router.go(-4);
        }
      });
    },
    // 获取验证码
    getCode () {
      if (!this.$tools.verifyPhone(this.phone)) {
        this.$toast("请输入正确的手机号");
        return;
      }
      this.timeDown();
      getMessageCode({
        userPhone: this.$tools.AESEncrypt(this.phone)
      }).then(res => {
        if (res.code === 0) {
          console.log(res);
        } else {
          this.second = this.maxSecond;
          clearInterval(this.timer);
        }
      });
    }
  },
  beforeDestroy () {
    this.timer && clearTimeout(this.timer);
  },
  computed: {
    ...mapState(["loginInfo"])
  },
  components: {}
};
</script>
<style lang="scss" scoped>
.CancellationAccount {
  background: #fff;
  padding: 0.2rem 0.4rem;
  height: 100%;
  .van-cell {
    height: 0.58rem;
    background: transparent;
    padding: 0;
    border-bottom: 1px solid #e9eaed;
    &::after {
      content: none;
    }
    input {
      font-size: 0.16rem;
      color: $mainBlack;
      &::placeholder {
        font-size: 0.16rem;
        color: #8d8d91;
      }
    }
    .van-field__button {
      font-size: 0.16rem;
      color: #6e727a;
      .active {
        color: $mainRed;
      }
    }
  }

  .loginBtn {
    background-image: linear-gradient(to right, #ff8f6e, #fd4b4b);
    border-radius: 0.29rem;
    height: 0.54rem;
    margin: 0.5rem auto;
    text-align: center;
    color: white;
    text-align: center;
    line-height: 0.54rem;
    text-indent: 0rem;
  }
}
</style>
